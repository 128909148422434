
// Composant plugin intro
.pluginIntro {
    background: url('#{$imgPath}bg-intro.png') no-repeat center bottom;
    position: relative;
    padding: 40px 0 0;
    min-height: 370px;
    .container {
        font-size: 0;
    }
    .h2like {
        font-size: $fsize48;
        line-height: 56px;
        text-align: left;
        margin: 0 0 58px;
    }
    .arrowDown {
        position: absolute;
        bottom: -26px;
        left: 50%;
        transform: translateX(-50%);
        &:hover {
            background-position: center bottom 10px;
        }
    }
}
.pluginIntro__col1,
.pluginIntro__col2 {
    font-size: $fsize16;
    display: inline-block;
    vertical-align: top;
}
.pluginIntro__col1 {
    width: 510px;
    padding: 0 0 0 68px;
    box-sizing: border-box;
}
.pluginIntro__col2 {
    font-size: $fsize21;
    width: calc(100% - 510px);
    margin-top: 100px;
}

.browsers__list {
    font-size: 0;
    margin-left: -10px;
}
.browsers__item {
    display: inline-block;
    background-color: white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 55px;
    text-indent: 100px;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 20px;
    box-shadow: 6px 10px 33px 0 rgba(0, 156, 255, 0.1);
    margin: 0 0 0 10px;
    a {
        display: block;
        width: 70px;
        height: 70px;
    }

    &.safari {
        background-image: url('#{$imgPath}browser-safari-v.png');
        &.active,
        &:hover {
            background-image: url('#{$imgPath}browser-safari.png');
        }
    }
    &.firefox {
        background-image: url('#{$imgPath}browser-firefox-v.png');
        &.active,
        &:hover {
            background-image: url('#{$imgPath}browser-firefox.png');
        }
    }
    &.chrome {
        background-image: url('#{$imgPath}browser-chrome-v.png');
        &.active,
        &:hover {
            background-image: url('#{$imgPath}browser-chrome.png');
        }
    }
    &.edge {
        background-image: url('#{$imgPath}browser-edge-v.png');
        &.active,
        &:hover {
            background-image: url('#{$imgPath}browser-edge.png');
        }
    }
}

.pluginIntro__listcheck {
    margin: 0 0 30px;
}
.pluginIntro__itemcheck {
    padding-left: 30px;
    background: url('#{$imgPath}check-rounded.svg') no-repeat 0 3px;
    background-size: 18px auto;
    margin: 10px 0 0;
    &:first-child {
        margin: 0;
    }
}


// Composant plugin steps
.pluginSteps {
    padding: 38px 0 0;
    .h2like {
        margin: 0 0 70px;
    }
}
.pluginSteps__txt {
    text-align: center;
    color: $color-black;
}
.pluginSteps__list {
    display: flex;
    justify-content: space-around;
    margin: 0 0 100px;
}
.pluginSteps__item {
    width: 190px;
}
.pluginSteps__img {
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 6px 10px 33px 0px rgba(0, 156, 255, 0.2);
    margin: 0 auto 28px auto;
    background-position: center center;
    background-repeat: no-repeat;
    &:after {
        content: '';
        width: 108px;
        height: 2px;
        background: $color-grey-dark;
        position: absolute;
        right: -168px;
        top: 50%;
        transform: translateY(-50%);
    }
    .pluginSteps__item:last-child &:after {
        display: none;
    }
    .install & {
        background-image: url('#{$imgPath}step-click.svg');
        background-size: 55px auto;
    }
    .detection & {
        background-image: url('#{$imgPath}step-detection.svg');
        background-size: 45px auto;
    }
    .credit & {
        background-image: url('#{$imgPath}step-credit.svg');
        background-size: 100% auto;
    }
}

.pluginSteps__count {
    position: absolute;
    bottom: -10px;
    left: -4px;
    color: white;
    background: $color-blue-brand;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    font-size: $fsize18;
    line-height: 24px;
}

.pluginpage .testimonialsComp__item {
    box-shadow: 6px 10px 33px 0px rgba(14, 208, 148, 0.2); // A vérifier
}

// En quelques mots
.pluginSteps__succinctly {
    background: url('#{$imgPath}plugin-capture.png') no-repeat right center;
    margin: 0 0 30px;
    padding: 66px calc(50% + 120px) 0 0;
    min-height: 546px;
    box-sizing: border-box;
}
.pluginSteps__succinctly__title {
    font-size: $fsize32;
    line-height: 32px;
    color: $color-blue-brand;
    margin: 0 0 40px;
    @extend %helvetica;
}
.pluginSteps__succinctly__text {
    font-size: $fsize18;
    line-height: 21px;
    margin: 0 0 25px;
}

// bloc testi
.pluginpage .testimonialsComp {
    .testimonialsComp__list {
        justify-content: space-between;
    }
    .testimonialsComp__item {
        margin: 0;
        &:first-child:before {
            content: '';
            background: url('#{$imgPath}etoileBleue.png') no-repeat center center;
            background-size: auto 100%;
            width: 30px;
            height: 40px;
            position: absolute;
            bottom: 6px;
            left: -17px;
        }
        &:last-child:before {
            content: '';
            background: url('#{$imgPath}etoileVerte.png') no-repeat center center;
            background-size: auto 100%;
            width: 40px;
            height: 50px;
            position: absolute;
            top: -34px;
            right: 0;
        }
    }
    .btnBar--right {
        margin: 0;
        .btn {
            width: 232px;
        }
    }
}

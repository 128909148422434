.header {
    width: 100%;
    box-sizing: border-box;
    padding: 22px 21px;
    position: relative;
}
.navPrimary__list {
    justify-content: end;
    & > li {
        flex-grow: 0;
    }
}
.navPrimary__item {
    display: none;
}
.header {
    .logo,
    .burger,
    .socFace,
    .socInsta {
        display: block;
    }
    .logo {
        // A utiliser au cas ou le push margin ne fonctionne pas
        // position: absolute;
        // left: 0;
        // top: 50%;
        // transform: translateY(-50%);
        margin-right: auto;
    }
    .logoMB {
        top: 0;
    }
    .socFace,
    .socInsta {
        border: 0;
        padding: 0;
        a {
            width: 30px;
            height: 30px;
        }
    }
}
.burger {
    margin: 0 0 0 30px;
    & > a {
        display: block;
        width: 30px;
        height: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 100px;
        background: url('#{$imgPath}burger.svg') no-repeat center center;
        background-size: 22px auto;
        position: relative;
        z-index: 2;
    }
    &.isOpen {
        & > a {
            background: url('#{$imgPath}cross.svg') no-repeat center center;
            background-size: 20px auto;
        }
    }
}
.burgerMenu {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background: $color-white;
    width: 45vw;
    height: 266px;
    box-shadow: 6px 10px 33px 0 rgba(0, 156, 255, 0.2);
    box-sizing: border-box;
    padding: 60px 20px 0 0;
    text-align: right;
    font-size: 14px;
    line-height: 30px;
    z-index: 1;
    .burger.isOpen & {
        display: block;
    }
}
.burgerMenu__item {
    a:not(.btn) {
        color: $color-burgermenu-link;
        text-decoration: none;
        display: block;
    }
    &.connect a {
        color: #0ED094;
    }
    .btn {
        margin: 10px 0 0;
    }
}

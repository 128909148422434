// Mobile: 757px
@media screen and (max-width: $small - 1) {
    @import "../base/global-mobile";
    @import "../partials/header-mobile";
    @import "../partials/footer-mobile";
    @import "../pages/home-mobile";
    @import "../pages/merchants-mobile";
    @import "../pages/apropos-mobile";
    @import "../pages/cg-mobile";
    @import "../pages/plugin-mobile";
    @import "../components/buttons-mobile";
}

// Tablet: 758px <-> 1023px
@media screen and (min-width: $small) and (max-width: $medium - 1) {
    @import "../base/global-tablet";
    @import "../partials/header-tablet";
    @import "../pages/home-tablet";
    @import "../pages/apropos-tablet";
    @import "../pages/cg-tablet";
    @import "../pages/plugin-tablet";
    @import "../components/buttons-tablet";
}

// jusqua -> 1023px
@media screen and (max-width: $medium - 1) {
    @import "../base/global-resp";
    @import "../pages/merchants-resp";
    @import "../pages/partners-resp";
    @import "../pages/apropos-resp";
    @import "../components/cards-resp";
}

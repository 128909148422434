.container {
    padding: 0 36px;
}
.h2like {
    font-size: 36px;
    line-height: 40px;
}
.testipage.testimonialsComp .btnBar--center {
    margin: 0 0 40px;
}

@charset "UTF-8";

// BASE
@import "base/normalize";
@import "base/colors";
@import "base/variables";
@import "base/typography";
@import "base/global";

// PARTIALS
@import "partials/header";
@import "partials/footer";

// COMPONENTS
@import "components/buttons";
@import "components/cards";
@import "components/sliders";
@import "components/video";

// PAGES
@import "pages/home";
@import "pages/apropos";
@import "pages/plugin";
@import "pages/partners";
@import "pages/merchants";
@import "pages/cg";
@import "pages/intersti";

// RESPONSIVE
@import "base/media-queries";

.container {
    width: 100%;
}

.connexion {
    margin: 0 0 40px;
}
.form {
    .fieldsContainer {
        width: 100%;
        max-width: 310px;
        box-sizing: border-box;
    }
}
.formLegend {
    font-size: 20px;
}

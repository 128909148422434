.cofondateurs,
.invests {
    .container {
        width: 100%;
        box-sizing: border-box;
    }
}
.cofondateurs {
    padding-bottom: 80px;
}
.invests {
    padding-bottom: 30px;
}

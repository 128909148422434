.interstipage {
    .header {
        height: 78px;
    }
    .main {
        height: calc(100vh - 393px);
    }
    .footer {
        height: 260px;
    }

    // Modal
    .modalInner {
        text-align: center;
        padding: 20px;
        font-size: 18px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        .modalTitle {
            margin: 0 0 40px;
        }
        p {
            margin: 0 0 20px;
            a {
                text-decoration: underline;
                color: $color-blue-brand;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .modalLogo {
            margin: 20px 0 60px;
        }
    }
    .vbox-figlio {
        border-radius: 10px;
    }
    .vbox-overlay {
        background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0% !important;
    }
    .vbox-close {
        display: none !important;
    }
}

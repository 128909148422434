// Sliders
.slider__pastille {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 6px 10px 33px 0 rgba(0, 156, 255, 0.1);
    margin: 0 12px;
    img {
        display: block;
        max-width: 46px;
        height: auto;
    }
}
.TickerNews{
    width: 100%;
    line-height: 48px;
    .ti_wrapper{
        width: 100%;
        position: relative;
    }
    .ti_slide{
        width: 30000px;
        position: relative;
        left: 0;
        top: 0;
    }
    .ti_content{
        width: 8000px;
        position: relative;
        float: left;
    }
    .ti_news{
        float: left;
    }
}

// Slider Slick
#sliderHomeTesti.slick-slider {
    position: relative;
    display: block !important;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 40px 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 100%;
    border: 0;
    overflow: hidden;
    text-indent: 50px;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    margin: 0;
    padding: 0;
}
.slick-prev {
    left: -40px;
    background: url('#{$imgPath}ico-arrow-left-blue.png') no-repeat center center;
    transition: background-position ease 0.25s;
    &:hover {
        background-position: left center;
    }
}
.slick-next {
    right: -40px;
    background: url('#{$imgPath}ico-arrow-right-blue.png') no-repeat center center;
    transition: background-position ease 0.25s;
    &:hover {
        background-position: right center;
    }
}

.footer {
    padding-left: 0;
    padding-right: 0;
    .logoMB {
        margin: 0 0 20px;
    }
}
.footer__nav {
    justify-content: start;
    flex-wrap: wrap;
    margin: 0;
    & > ul {
        width: 50%;
        box-sizing: border-box;
        margin: 0 0 35px 0;
    }
    .footer__nav__social {
        text-align: left;
    }
    .footer__nav__qrcode {
        text-align: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

.container {
    padding: 0 21px;
}
.heading {
    margin: 0 0 30px;
}
.h2like {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 30px;
    br {
        display: none;
    }
}
.charte {
    margin: 55px 0;
    flex-direction: column;
    align-items: center;
    .charte__txt {
        text-align: center;
        margin: 0;
        padding: 20px 12px;
        line-height: 20px;
        width: 175px;
        margin: 0 0 20px;
        &:after {
            right: auto;
            top: auto;
            bottom: -19px;
            left: 50%;
            transform: rotate(90deg) translateY(50%);
        }
    }
}

// Variables

// Images path
$imgPath: '../images/';

// Font-family
$font-gudea: 'Gudea', sans-serif;
$font-helvetica: 'Helvetica Neue', sans-serif;

// Font-Size
$fsize48: 48px;
$fsize42: 42px;
$fsize36: 36px;
$fsize32: 32px;
$fsize24: 24px;
$fsize21: 21px;
$fsize20: 20px;
$fsize18: 18px;
$fsize16: 16px;
$fsize14: 14px;
$fsize12: 12px;
$fsize11: 11px;

// Media-queries
$small: 757px;
$medium: 1024px;

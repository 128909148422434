// Homepage

//- Bloc intro
.introComp {
    background: url('#{$imgPath}bg-intro.png') no-repeat center bottom;
    min-height: 410px;
    position: relative;
    padding: 16px 0 0;
    .container {
        font-size: 0;
    }
    .introComp__col1,
    .introComp__col2 {
        font-size: $fsize16;
        display: inline-block;
        width: 50%;
        vertical-align: top;
    }
    .introComp__col1 {
        padding: 20px 30px 0 115px;
        box-sizing: border-box;
    }
    .arrowDown {
        position: absolute;
        bottom: -26px;
        left: 50%;
        transform: translateX(-50%);
        &:hover {
            background-position: center bottom 10px;
        }
    }
    .h2like {
        text-align: left;
        font-size: $fsize48;
        line-height: 48px;
        max-width: 250px;
        margin: 0 0 35px;
        min-height: 150px;
    }
    .btn {
        margin: 0 0 10px;
    }
}
.introComp__frontText {
    font-size: $fsize18;
    line-height: 20px;
    margin: 0 0 28px;
}
.introComp__legend {
    color: $color-home-intro-legend;
    font-size: $fsize12;
    line-height: 13px;
}
.homeVideo {
    background: url('#{$imgPath}bg-home-video.png') no-repeat right top -38px;
    background-size: 100% auto;
    position: relative;
    width: 500px;
    height: 400px;
    .homeVideo__play {
        position: absolute;
        left: 50%;
        top: 130px;
        transform: translateX(-50%);
        cursor: pointer;
        width: 126px;
        height: 126px;
        border: none;
        background: url('#{$imgPath}bg-home-play.png') no-repeat center center;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 200px;
        padding: 0;
    }
}

//- Bloc Merchants
.merchantsComp {
    padding: 54px 0 130px 0;
    background: url('#{$imgPath}bg-home-merchants.png') no-repeat center bottom;
    min-height: 510px;
    box-sizing: border-box;
}
.merchantsComp__text {
    font-size: $fsize18;
    line-height: 20px;
    text-align: center;
    margin: 0 0 40px;
}
.merchantsComp__slider {
    margin: 0 0 35px;
    height: 80px;
    overflow: hidden;
}

//- Bloc Testimonials
.testimonialsComp {
    min-height: 434px;
    .innerContainer {
        margin: 0 62px;
    }
    .btnBar--right {
        // margin-right: 20px;
        margin: 0 20px;
        font-size: 0;
    }
    .btnBar--right .btn--link {
        font-size: 14px;
        padding: 0 15px;
    }
}
.testimonialsComp__text {
    font-size: $fsize18;
    line-height: 20px;
    text-align: center;
    margin: 0 0 64px;
}
.testimonialsComp__list {
    display: flex;
    justify-content: space-around;
    margin: 0 0 40px;
}
#sliderHomeTesti.testimonialsComp__list {
    height: 270px;
    &:before {
        content: '';
        background: url('#{$imgPath}etoileBleue.png') no-repeat center center;
        background-size: auto 100%;
        width: 30px;
        height: 40px;
        position: absolute;
        bottom: 45px;
        left: 5px;
        z-index: 10;
    }
    &:after {
        content: '';
        background: url('#{$imgPath}etoileVerte.png') no-repeat center center;
        background-size: auto 100%;
        width: 40px;
        height: 50px;
        position: absolute;
        top: 0;
        right: 10px;
    }
}

.testimonialsComp__item {
    box-shadow: 6px 10px 33px 0px rgba(1, 1, 1, 0.1);
    width: 232px;
    height: 190px;
    background: $color-white;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 30px 12px 50px 12px;
    font-size: $fsize16;
    line-height: 19px;
    position: relative;
    margin: 0 20px;
    .author {
        color: $color-home-testi-author;
        position: absolute;
        bottom: 23px;
        right: 12px;
        padding-left: 34px;
    }
}

//- Bloc Partners
.partnersComp {
    padding: 120px 0 0;
    background: url('#{$imgPath}bg-home-merchants2.png') no-repeat center 0;
    box-sizing: border-box;
    .innerContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 290px;
        margin: 0 0 24px;
    }
    .btnBar--right {
        margin: 0 194px 160px 0;
    }
}
// Decorations :(
.partnersComp__deco {
    position: relative;
    &:before {
        content: '';
        background: url('#{$imgPath}etoileBleue.png') no-repeat center center;
        background-size: auto 100%;
        width: 30px;
        height: 40px;
        position: absolute;
        bottom: 16px;
        left: -17px;
    }
    &:after {
        content: '';
        background: url('#{$imgPath}etoileVerte.png') no-repeat center center;
        background-size: auto 100%;
        width: 40px;
        height: 50px;
        position: absolute;
        top: -24px;
        right: 20px;
    }
}
.partnersComp__text {
    font-size: $fsize18;
    line-height: 20px;
    text-align: center;
    margin: 0 0 70px;
}
.partnersComp__rewards {
    border-radius: 8px;
    width: 230px;
    height: 290px;
    box-shadow: 6px 10px 33px 0px rgba(1, 156, 255, 0.1);
    background: $color-home-rewards-bg url('#{$imgPath}cumulez.svg') no-repeat center 36px;
    background-size: auto 50px;
    padding: 117px 30px 20px 30px;
    box-sizing: border-box;
    text-align: center;
    margin: 0 40px 0 0;
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -15px;
        transform: translateY(-50%);
        background: url('#{$imgPath}arrow-right-white.png') no-repeat 0 0;
        width: 15px;
        height: 23px;
    }
}
.partnersComp__rewardsTxt1 {
    margin: 0 0 24px;
}
.partnersComp__rewardsTxt2 {
    @extend %gudeabold;
}
.partnersComp__list ul {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    margin: 0 0 0 -20px;
}
.partnersComp__item {
    margin: 0 0 20px 20px;
}

.btnBar--flex {
    margin: 0 !important;
    flex-direction: column;
    .emptyElement {
        display: none;
    }
    .btn {
        width: 100% !important;
    }
}

// Partenaires
.partners__grid {
    padding: 33px 0 100px 0;
}
.partners__grid__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.partners__grid__item {
    background-color: $color-partners-grid-item-bg;
    text-align: center;
    width: 244px;
    height: 238px;
    border-radius: 9px;
    box-shadow: 6px 10px 33px 0 rgba(0, 156, 255, 0.1);
    margin: 14px;
    box-sizing: border-box;
    padding: 15px 20px 73px 20px;
    position: relative;
    color: $color-partners-grid-txt;
    // Diams
    &:nth-child(1):before,
    &:nth-child(3):before,
    &:nth-child(5):before,
    &:nth-child(7):before,
    &:nth-child(9):before {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-position: 0 0;
    }
    &:nth-child(1):before {
        background-image: url('#{$imgPath}etoileVerte.png');
        background-size: auto 100%;
        top: -20px;
        left: -15px;
        width: 30px;
        height: 40px;
    }
    &:nth-child(3):before {
        background-image: url('#{$imgPath}etoileVerte.png');
        background-size: auto 100%;
        top: 90px;
        right: -15px;
        width: 30px;
        height: 40px;
    }
    &:nth-child(5):before {
        background-image: url('#{$imgPath}etoileBleue.png');
        background-size: auto 100%;
        bottom: -20px;
        left: 20px;
        width: 30px;
        height: 40px;
    }
    &:nth-child(7):before {
        background-image: url('#{$imgPath}etoileVerte.png');
        background-size: auto 100%;
        top: -20px;
        left: 60%;
        width: 30px;
        height: 40px;
    }
    &:nth-child(9):before {
        background-image: url('#{$imgPath}etoileVerte.png');
        background-size: auto 100%;
        bottom: -20px;
        right: 40px;
        width: 30px;
        height: 40px;
    }
    .btn--grid {
        position: absolute;
        bottom: 27px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.partners__grid__img {
    width: 180px;
    height: 70px;
    margin: 0 auto 25px auto;
    img {
        max-width: 100%;
        height: auto;
    }
}
.partners__grid__txt {
    line-height: 21px;
}

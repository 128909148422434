// Cartes
.card {
    position: relative;
    width: 192px;
    text-align: center;
    padding: 0 0 12px;
}
.card__pic {
    border-radius: 50%;
    position: relative;
    max-width: 144px;
    height: auto;
    z-index: 2;
}
.card__txt {
    background: $color-cards-bg;
    border-radius: 8px;
    box-shadow: 6px 10px 33px 0 rgba(1, 1, 1, 0.1);
    box-sizing: border-box;
    padding: 60px 0 15px 0;
    height: 190px;
    margin: -42px 0 0;
    position: relative;
    z-index: 1;
}
.card__who {
    color: $color-cards-who;
    font-size: $fsize20;
    line-height: 21px;
    margin: 0 0 6px;
    @extend %helvetica;
}
.card__role {
    color: $color-cards-role;
    margin: 0 0 10px;
    @extend %helvetica;
}
.card__explain {
    color: $color-cards-explain;
    font-size: $fsize14;
}
.card__linkedin {
    background: url('#{$imgPath}linkedin-little.png') no-repeat center center;
    background-size: auto 100%;
    width: 30px;
    height: 25px;
    text-indent: 200px;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
}

// Listing
.card__list {
    display: flex;
    justify-content: center;
}
.card__item {
    margin: 0 10px;
}

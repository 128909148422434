// Global colors
$color-black: #000;
$color-white: #FFF;
$color-grey: #FBFBFB;
$color-grey2: #F7F7F7;
$color-grey3: #BFBFBF;
$color-grey-dark: #828282;
$color-grey-dark2: #868686;
$color-blue-brand: #009CFF;
$color-blue-light: #D0ECFE;
$color-green-brand: #0ED094;
$color-almost-black: #3E3E3E;
$color-red: #d86464;

// Color variables
$color-global-txt: $color-almost-black;
$color-global-bg: $color-white;
$color-global-link: $color-blue-brand;
$color-global-h2like: $color-blue-brand;
$color-form-input-border: $color-blue-light;
$color-form-input-placeholder: $color-almost-black;
$color-form-error: $color-red;
$color-header-nav-link: $color-grey-dark2;
$color-header-nav-hover: $color-blue-brand;
$color-header-nav-connect: $color-green-brand;
$color-header-nav-border: $color-grey-dark2;
$color-footer-bg: $color-grey;
$color-footer-link: $color-grey-dark2;
$color-footer-nav-border: $color-grey-dark2;
$color-footer-copyright: $color-grey-dark;
$color-btn-txt: $color-white;
$color-btn-primary-bg: $color-green-brand;
$color-btn-secondary-bg: $color-blue-brand;
$color-btn-link-bg: $color-grey2;
$color-btn-link-txt: $color-blue-brand;
$color-home-intro-legend: $color-grey-dark2;
$color-home-testi-author: $color-blue-brand;
$color-home-rewards-bg: $color-white;
$color-cards-bg: $color-white;
$color-cards-who: $color-blue-brand;
$color-cards-role: $color-grey-dark;
$color-cards-explain: $color-almost-black;
$color-partners-grid-item-bg: $color-white;
$color-partners-grid-btn-bg: $color-blue-brand;
$color-partners-grid-btn-txt: $color-white;
$color-partners-grid-txt: $color-grey-dark2;
$color-cg-maj: $color-blue-brand;
$color-cg-nav: $color-grey-dark;
$color-cg-content-link: $color-blue-brand;
$color-cg-content-bold: $color-blue-brand;
$color-burgermenu-link: $color-grey-dark2;

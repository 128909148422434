// Buttons

// Bouton global
.btn {
    display: inline-block;
    border: none;
    border-radius: 3px;
    text-decoration: none;
    height: 36px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 36px;
    cursor: pointer;
    text-align: center;
    @extend %gudeabold;

    // Bouton primaire
    &--primary {
        color: $color-btn-txt;
        background-color: $color-btn-primary-bg;
        &.btn--decoLeft,
        &.btn--decoRight {
            background-image: url('#{$imgPath}btn-deco-blanc.png');
            background-repeat: no-repeat;
        }
    }

    // Bouton secondaire
    &--secondary {
        color: $color-btn-txt;
        background-color: $color-btn-secondary-bg;
        &.btn--decoLeft,
        &.btn--decoRight {
            background-image: url('#{$imgPath}btn-deco-blanc.png');
            background-repeat: no-repeat;
        }
    }

    // Bouton lien
    &--link {
        color: $color-btn-link-txt;
        background-color: $color-btn-link-bg;
        font-weight: normal;
        text-decoration: underline;
    }
    &--linknobg {
        color: $color-btn-link-txt;
        font-weight: normal;
        text-decoration: underline;
    }
    &--link,
    &--linknobg {
        &.btn--decoLeft,
        &.btn--decoRight {
            background-image: url('#{$imgPath}btn-deco-bleu.png');
            background-repeat: no-repeat;
        }
    }

    // Modificateurs
    &--decoLeft,
    &--decoRight {
        min-width: 215px;
        padding-left: 45px;
        padding-right: 45px;
    }
    &--decoLeft {
        background-position: 16px center;
    }
    &--decoRight {
        background-position: right 13px center;
    }
    &--rounded {
        border-radius: 9px;
    }


    // Grille
    &--grid {
        color: $color-partners-grid-btn-txt;
        background: $color-partners-grid-btn-bg;
        border-radius: 9px;
    }
}

// <a>
a.btn {
    box-sizing: border-box;
}


// Barre de boutons
.btnBar--center {
    text-align: center;
}
.btnBar--right {
    text-align: right;
}

.btnBar--flex {
    display: flex;
    justify-content: space-between;
    .emptyElement {
        width: 232px;
        display: block;
    }
}

// Header
.header {
    width: $medium;
    margin: 0 auto;
    padding: 22px 27px;
    box-sizing: border-box;
    .logoLinkTag {
        margin: 0;
    }
    .logoLink {
        display: block;
        width: 160px;
        height: 30px;
        line-height: 20px;
    }
    .logoMB {
        max-width: 100%;
        position: relative;
        top: -2px;
    }

    &.header-sticky {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        z-index: 100;
    }
}
.header.header-sticky + .anti-collapse {
    display: block;
}

.navPrimary__link {
    color: $color-header-nav-link;
    text-decoration: none;
    font-size: $fsize14;
    line-height: 21px;
    margin: 0 15px;
    display: block;
    .apropos &.apropos,
    .partnerspage &.partners,
    .merchantspage &.merchants,
    &:hover {
        color: $color-header-nav-hover;
    }
}

.socFace,
.socInsta{
    a {
        width: 27px;
        height: 27px;
        overflow: hidden;
        text-indent: 110%;
        white-space: nowrap;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
    }
}
.socFace {
    border-left: 1px solid $color-header-nav-border;
    padding-left: 20px;
    padding-right: 5px;
}
.socInsta {
    border-right: 1px solid $color-header-nav-border;
    padding-right: 20px;
    padding-left: 5px;
}
.socFace .navPrimary__link {
    background: url('#{$imgPath}logo_FB.png') no-repeat center center;
    background-size: auto 20px;
}
.socInsta .navPrimary__link {
    background: url('#{$imgPath}logo_Insta.png') no-repeat center center;
    background-size: auto 20px;
}
.burger {
    display: none;
}

.navPrimary__item.connect .navPrimary__link {
    color: $color-header-nav-connect;
    margin: 0 30px;
}

.navPrimary__list {
    display: flex;
    align-items: center;
    height: 34px;
    justify-content: center; //
    & > li {
        flex-grow: 1;
    }
}

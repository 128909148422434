// Global styles
body {
    background: $color-global-bg;
    color: $color-global-txt;
    font-size: $fsize16;
    @extend %gudea;
}

audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}

p {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

button:focus,
a:focus {
    outline-style: dotted;
    outline-width: 1px;
    outline-color: $color-blue-brand;
}

.container {
    width: $medium;
    margin: 0 auto;
    padding: 0 56px;
    box-sizing: border-box;
}

.heading {
    margin: 0 0 60px;
}
.h2like {
    color: $color-global-h2like;
    font-size: $fsize42;
    line-height: 50px;
    text-align: center;
    margin: 0 0 34px;
    @extend %helvetica;
}
.h2like--cloud {
    font-size: $fsize48;
    line-height: 48px;
    background: url('#{$imgPath}bg-h2likecloud.png') no-repeat center bottom;
    padding: 38px 0 54px 0;
    margin: 0;
}

.arrowDown {
    border: none;
    background-color: transparent;
    width: 100px;
    height: 80px;
    padding: 0;
    overflow: hidden;
    background: url('#{$imgPath}ico-arrow-down-blue.png') no-repeat center center;
    text-indent: 200px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-position ease 0.25s;
}

.formLegend {
    font-size: $fsize24;
    text-align: center;
    color: $color-blue-brand;
    margin: 0 0 34px;
    @extend %helvetica;
}

.connexion {
    margin: 0 0 80px;
}

.anti-collapse {
    display: none;
}

// Form
.form {
    .fieldsContainer {
        box-shadow: 6px 10px 33px 0px rgba(0, 156, 255, 0.2);
        width: 350px;
        margin: 0 auto 28px auto;
        padding: 25px 20px;
        border-radius: 9px;
        text-align: center;
    }
    .field {
        margin: 0 0 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    label {
        display: none;
    }
    input {
        height: 25px;
        font-size: $fsize14;
        border: 1px solid $color-form-input-border;
        box-sizing: border-box;
        padding: 0 10px;
        border-radius: 8px;
        width: 100%;
        // Placeholder
        &::-webkit-input-placeholder { // Chrome/Opera/Safari
            color: $color-form-input-placeholder;
        }
        &::-moz-placeholder { // Firefox 19+
            color: $color-form-input-placeholder;
        }
        &:-ms-input-placeholder { // IE 10+
            color: $color-form-input-placeholder;
        }
        &:-moz-placeholder { // Firefox 18-
            color: $color-form-input-placeholder;
        }
    }
    // Errors
    .formError {
        display: none;
    }
    .field--error {
        input {
            border-color: $color-form-error;
        }
        .formError {
            display: block;
            color: $color-form-error;
            text-align: left;
            font-size: $fsize14;
            padding: 2px 0 0 10px;
        }
    }

    .btnBar {
        text-align: center;
        .pwdForgot {
            margin: 8px 0 0;
        }
    }
    .pwdForgot {
        display: block;
        font-size: $fsize14;
        color: $color-grey3;
        &:hover {
            text-decoration: none;
        }
    }
}

.loadingBar {
    text-align: center;
    .loading {
        background: url('#{$imgPath}loadingShopzone.png') no-repeat center center;
        width: 40px;
        height: 40px;
        display: inline-block;
        background-size: 100% auto;
        overflow: hidden;
        text-indent: 100px;
        white-space: nowrap;
        animation: rotation 1.5s infinite linear;
    }
}

#isTyped {
    display: block;
    br {
        display: block !important;
    }
}

// Charte
.charte {
    display: flex;
    justify-content: center;
    margin: 80px 0;
    .charte__txt {
        height: 110px;
        width: 235px;
        box-shadow: 6px 10px 33px 0px rgba(0, 156, 255, 0.2);
        display: block;
        text-align: right;
        border-radius: 9px;
        color: $color-almost-black;
        text-decoration: none;
        padding: 35px 20px 20px;
        box-sizing: border-box;
        margin: 0 30px 0 0;
        position: relative;
        &:after {
            content: '';
            width: 15px;
            height: 23px;
            background: url('#{$imgPath}arrow-right-white.png') no-repeat center center;
            position: absolute;
            right: -15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .charte__img {
        background: url('#{$imgPath}pdf.svg') no-repeat center center;
        display: block;
        height: 110px;
        width: 60px;
        overflow: hidden;
        text-indent: 300px;
        white-space: nowrap;
    }
}

// Testimonials
.testipage.testimonialsComp {
    min-height: 0;
    .testimonialsComp__list {
        flex-wrap: wrap;
        margin: 0 0 58px;
        height: auto;
    }
    .testimonialsComp__item {
        margin: 0 0 32px;
        &:first-child:before {
            content: '';
            background: url('#{$imgPath}etoileBleue.png') no-repeat center center;
            background-size: auto 100%;
            width: 30px;
            height: 40px;
            position: absolute;
            bottom: 6px;
            left: -17px;
        }
        &:last-child:before {
            content: '';
            background: url('#{$imgPath}etoileVerte.png') no-repeat center center;
            background-size: auto 100%;
            width: 40px;
            height: 50px;
            position: absolute;
            top: -34px;
            right: 0;
        }
    }
    .btnBar--center {
        margin: 0 0 120px;
    }
}

// Animations
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

// Conditions générales
.cg {
    font-size: 0;
    margin: 0 0 70px;
}
.cgNav,
.cgContent {
    display: inline-block;
    vertical-align: top;
}
.cgNav {
    width: 340px;
    box-sizing: border-box;
    padding: 0 40px 0 60px;
}
.cgNav__list {
    padding: 0 0 0 20px;
}
.cgNav__item {
    font-size: $fsize18;
    color: $color-cg-nav;
    margin: 0 0 20px;
    &:last-child {
        margin-bottom: 0;
    }
    a {
        color: $color-cg-nav;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.cgContent {
    font-size: $fsize18;
    width: calc(100% - 340px);
    position: relative;
}
.cgMaj {
    color: $color-cg-maj;
    font-size: $fsize12;
    line-height: 16px;
    position: absolute;
    top: -30px;
    left: 0;
}
.cgContent__title {
    font-size: $fsize24;
    font-weight: normal;
    margin: 0 0 28px;
}
.cgContent__item {
    margin: 0 0 55px;
    &:last-child {
        margin-bottom: 0;
    }
    a {
        text-decoration: underline;
        color: $color-cg-content-link;
        &:hover {
            text-decoration: none;
        }
    }
    p {
        line-height: 21px;
        margin: 0 0 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .bold {
        color: $color-cg-content-bold;
    }
    ul {
        margin: 0 0 30px 40px;
        &:last-child {
            margin-bottom: 0;
        }
        li {
            list-style-type: disc;
            margin: 0 0 10px;
            &:last-child {
                margin: 0;
            }
        }
    }
    ol {
        margin: 0 0 30px 40px;
        padding: 0;
        &:last-child {
            margin-bottom: 0;
        }
        li {
            margin: 0 0 10px;
            &:last-child {
                margin: 0;
            }
        }
    }
}

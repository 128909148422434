.introComp {
    min-height: 0;
    padding-top: 0;

    .h2like {
        font-size: 40px;
        line-height: 44px;
        margin: 0;
        max-width: 50vw;
    }
    .introComp__col1,
    .introComp__col2 {
        width: 100%;
        display: block;
    }
    .introComp__col1 {
        padding: 0;
        margin: 0 0 20px;
    }
}
.introComp__frontText {
    font-size: 15px;
    line-height: 18px;
    br {
        display: none;
    }
}
.introComp__legend {
    br {
        display: none;
    }
}
.homeVideo {
    height: 315px;
    background-position: center center;
    background-size: auto 100%;
    .homeVideo__play {
        width: 90px;
        height: 90px;
        background-size: 100% auto;
        top: 105px;
    }
}
.merchantsComp {
    min-height: 0;
    padding: 32px 0 130px 0;
}
.merchantsComp__text {
    font-size: 16px;
    line-height: 18px;
    br {
        display: none;
    }
}
#sliderHomeTesti.testimonialsComp__list {
    height: auto;
    margin-bottom: 0;
}
.testimonialsComp {
    min-height: 0;
    .innerContainer {
        margin: 0 30px;
    }
    .btn {
        margin: 0 0 10px !important;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    .btnBar--right .btn--link {
        font-size: 13px;
    }
}
.testimonialsComp__text {
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 20px;
}
.testimonialsComp__list {
    flex-direction: column;
    align-items: center;
    margin: 0 0 40px;
}
.testimonialsComp__item {
    margin-bottom: 32px !important;
    width: 220px;
    height: 234px;
    &:last-child {
        margin-bottom: 0 !important;
    }
}
.partnersComp__text {
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 30px;
}
.partnersComp {
    padding: 120px 0 110px 0;
    .innerContainer {
        height: auto;
        flex-direction: column;
        margin: 0 0 35px;
    }
    .btnBar--right {
        margin: 0;
        text-align: center;
    }
}
.partnersComp__rewards {
    margin: 0;
    background-size: auto 40px;
    padding: 100px 30px 20px 30px;
    &:after {
        top: auto;
        bottom: -19px;
        right: auto;
        left: 50%;
        transform: rotate(90deg) translateY(50%);
    }
}
.partnersComp__deco {
    margin: 0 0 45px;
}
.partnersComp__list {
    ul {
        flex-direction: column;
        width: 100%;
        margin: 0;
    }
}
.partnersComp__item {
    margin: 0 0 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

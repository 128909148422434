// Typography web

// Family : Gudea
//- Regular
@font-face {
    font-family: 'Gudea';
    src: url('../fonts/Gudea.eot');
    src: url('../fonts/Gudea.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gudea.woff2') format('woff2'),
    url('../fonts/Gudea.woff') format('woff'),
    url('../fonts/Gudea.ttf') format('truetype'),
    url('../fonts/Gudea.svg#Gudea') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
//- Italic
@font-face {
    font-family: 'Gudea';
    src: url('../fonts/Gudea-Italic.eot');
    src: url('../fonts/Gudea-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gudea-Italic.woff2') format('woff2'),
    url('../fonts/Gudea-Italic.woff') format('woff'),
    url('../fonts/Gudea-Italic.ttf') format('truetype'),
    url('../fonts/Gudea-Italic.svg#Gudea-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
//- Bold
@font-face {
    font-family: 'Gudea';
    src: url('../fonts/Gudea-Bold.eot');
    src: url('../fonts/Gudea-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gudea-Bold.woff2') format('woff2'),
    url('../fonts/Gudea-Bold.woff') format('woff'),
    url('../fonts/Gudea-Bold.ttf') format('truetype'),
    url('../fonts/Gudea-Bold.svg#Gudea-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


// Family : Helvetica Neue
//- Thin
@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Thin.eot');
    src: url('../fonts/HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeue-Thin.woff2') format('woff2'),
    url('../fonts/HelveticaNeue-Thin.woff') format('woff'),
    url('../fonts/HelveticaNeue-Thin.ttf') format('truetype'),
    url('../fonts/HelveticaNeue-Thin.svg#HelveticaNeue-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


// Placeholders : Gudea
%gudea {
    font-family: $font-gudea;
    font-weight: normal;
    font-style: normal;
}
%gudeait {
    font-family: $font-gudea;
    font-weight: normal;
    font-style: italic;
}
%gudeabold {
    font-family: $font-gudea;
    font-weight: bold;
    font-style: normal;
}

// Placeholders : Helvetica Neue
//- Thin
%helvetica {
    font-family: $font-helvetica;
    font-weight: 100;
    font-style: normal;
}

.cofondateurs {
    background: url('#{$imgPath}bg-gradient-diams.png') no-repeat center bottom;
    padding-bottom: 165px;
    // Deco
    .card__picDeco {
        position: relative;
        &:before {
            content: '';
            background: url('#{$imgPath}etoileVerte.png') no-repeat center center;
            width: 30px;
            height: 40px;
            position: absolute;
            z-index: 3;
        }
    }
    .card__item:nth-child(1) .card__picDeco:before {
        background: url('#{$imgPath}etoileVerte.png') no-repeat center center;
        background-size: auto 80%;
        top: 14px;
        left: 17px;
    }
    .card__item:nth-child(2) .card__picDeco:before {
        background: url('#{$imgPath}etoileBleue.png') no-repeat center center;
        background-size: auto 80%;
        bottom: 18px;
        left: 18px;
    }
    .card__item:nth-child(3) .card__picDeco:before {
        background: url('#{$imgPath}etoileVerte.png') no-repeat center center;
        background-size: auto 80%;
        bottom: 32px;
        right: 15px;
    }
    .card__item:nth-child(4) .card__picDeco:before {
        background: url('#{$imgPath}etoileBleue.png') no-repeat center center;
        background-size: auto 80%;
        top: 0;
        right: 28px;
    }
}

.cofondateurs,
.invests {
    .h2like {
        font-size: $fsize36;
        line-height: 35px;
        margin: 0 0 70px;
    }
}

.invests {
    padding-bottom: 105px;
    .h2like {
        margin-top: 30px;
    }
}

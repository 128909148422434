// Footer
.footer {
    background: $color-footer-bg;
    padding: 35px 56px 20px 56px;
    font-size: $fsize16;
    
    a {
        color: $color-footer-link;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    .logoMB {
        max-width: 200px;
        // height: auto;
        margin: 0 0 40px;
    }

    .copyright {
        color: $color-footer-copyright;
        font-size: $fsize12;
        text-align: center;
        // <span> car pb typo sur glyphe (c)
        span {
            font-size: $fsize16;
        }
    }
}

.footer__nav {
    display: flex;
    justify-content: space-between;
    margin: 0 0 60px;
    & > ul {
        flex-grow: 1;
        padding: 0 0 0 14px;
        border: 0 solid transparent;
        border-left: 1px solid $color-footer-nav-border;
    }
    ul a {
        line-height: 24px;
    }
}

.footer__nav .footer__nav__social {
    text-align: right;
    border: 0;
}
.badgeAppStore a, .badgeGooglePlay a {
    display: inline-block;
    width: 117px;
    text-indent: 110%;
    overflow: hidden;
    white-space: nowrap;
}
.badgeAppStore a {
    background: url('#{$imgPath}badge-appstore.png') no-repeat 0 0;
    height: 38px;
    margin: 0 0 10px;
    background-size: 100% auto;
}
.badgeGooglePlay a {
    background: url('#{$imgPath}badge-googleplay.png') no-repeat 0 0;
    height: 43px;
    background-size: 100% auto;
}
ul.footer__nav__qrcode {
    border: 0;
    .qrcode img {
        width: 100px;
        height: auto;
    }
}

.introComp {
    .introComp__col1 {
        padding-left: 0;
    }
}
.introComp__frontText {
    font-size: 16px;
    line-height: 18px;
}
.merchantsComp__text {
    font-size: 16px;
    line-height: 18px;
}
.homeVideo {
    background-position: right center;
}
.testimonialsComp {
    .innerContainer {
        margin: 0;
    }
    .btnBar--right {
        margin-right: 0;
        text-align: center;
        .btn--link {
            padding: 0;
        }
    }
}
.testimonialsComp__item {
    width: 210px;
    height: 224px;
}
.partnersComp {
    .btnBar--right {
        margin: 0 0 30px;
    }
}

.header {
    width: 100%;
    padding: 16px;
    .logoLink {
        height: 25px;
        width: 145px;
    }
    .logoMB {
        top: -4px;
    }
}
.navPrimary__list {
    height: 25px;
    .btn {
        width: 85px;
        height: 25px;
        box-sizing: border-box;
        font-size: 13px;
        padding: 0;
        line-height: 25px;
    }
}
.navPrimary__link {
    font-size: 13px;
    margin: 0 10px;
}
.socFace,
.socInsta {
    padding: 0;
}
.navPrimary__item.connect .navPrimary__link {
    margin: 0 10px;
}

.pluginIntro__col1 {
    width: 420px;
    padding: 0 0 0 95px;
}
.pluginIntro__col2 {
    width: calc(100% - 420px);
    margin: 30px 0 0;
}
.pluginIntro {
    padding: 20px 0 0;
    min-height: 300px;
    .h2like {
        font-size: 40px;
        line-height: 40px;
        margin: 0 0 40px;
    }
}
.browsers__item {
    background-size: auto 40px;
    a {
        width: 54px;
        height: 54px;
    }
}
.pluginIntro__itemcheck {
    font-size: 18px;
    line-height: 21px;
}
.pluginSteps__img {
    width: 75px;
    height: 75px;
}
.pluginSteps__succinctly {
    padding: 0 50% 0 0;
    background-size: 340px auto;
    background-position: right 0;
    min-height: 400px;
}

.cg {
    margin: 0 0 30px;
}
.cgMaj {
    position: static;
    margin: 10px 0;
}
.cgNav {
    width: 100%;
    padding: 0;
}
.cgContent {
    width: 100%;
}
.cgNav__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.cgNav__item {
    width: 50%;
    list-style-position: inside;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
}
.cgContent__item {
    margin: 0 0 20px;
    p {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 20px;
    }
    ul, ol {
        font-size: 14px;
    }
}
.cgContent__title {
    margin: 0 0 14px;
}

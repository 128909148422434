.cg {
    margin: 0 0 50px;
}
.cgNav {
    width: 230px;
    padding: 0 20px 0 15px;
}
.cgContent {
    width: calc(100% - 230px);
}

.card__list {
    flex-wrap: wrap;
}
.card__item {
    margin: 0 18px 48px 18px;
    &:last-child {
        margin-bottom: 0;
    }
}
.card {
    width: 223px;
}

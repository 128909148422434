.pluginIntro__col1 {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
}
.pluginIntro__col2 {
    width: 100%;
    display: block;
    margin: 0;
}
.pluginIntro {
    min-height: 480px;
    padding-top: 10px;
    .h2like {
        font-size: 40px;
        line-height: 44px;
        margin: 0 0 15px;
    }
}
.browsers__list {
    margin: 0 0 20px -10px;
}
.pluginIntro__listcheck {
    margin: 0 0 20px;
}
.pluginIntro__itemcheck {
    font-size: 18px;
    line-height: 27px;
    margin: 0;
}
.pluginSteps__list {
    flex-direction: column;
    align-items: center;
    margin: 0 0 30px;
}
.pluginSteps__item {
    margin: 0 0 80px;
    &:last-child {
        margin: 0 0 20px;
    }
}
.pluginSteps__img {
    &:after {
        width: 1px;
        height: 50px;
        right: auto;
        left: 50%;
        top: auto;
        bottom: -130px;
        transform: translateX(-50%);
    }
}
.pluginSteps__succinctly {
    padding: 0;
    min-height: 0;
    background-position: center bottom 28px;
    background-size: 310px auto;
    .btn {
        margin-top: 380px;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        display: block;
    }
}
.pluginSteps__succinctly__title {
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 20px;
    br {
        display: none;
    }
}
.pluginSteps__succinctly__text {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 15px;
}
.pluginpage {
    .testimonialsComp {
        margin: 0 0 30px;
    }
}
